import React, { FC, useEffect } from 'react'
import ReactHoverObserver from 'react-hover-observer'
import Link from 'next/link'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Dialog } from '@docpace/shared-react-components/Dialog'
import { useAtom } from 'jotai'

import {
    ManagerOverviewQueryDocument,
    AdminAdminDetailQueryDocument,
} from '@docpace/admin-graphql/dist/urql.types'
import {
    ADMIN_SIDEBAR_DRAWER_WIDTH,
    ADMIN_SIDEBAR_DRAWER_WIDTH_CLOSED,
    AdminHeader,
    AdminSidebar,
    AdminLayoutNav,
} from '@docpace/admin-react-components'
import adminRoutes from '@docpace/shared-react-routes/adminRoutes'
import { useNextQueryParam } from '@docpace/shared-react-hooks/useNextQueryParam'
import { useAdminPracticeBase } from '@docpace/admin-react-hooks'
import { useAdminBase, useAdminDashboardSettings } from '@docpace/admin-react-hooks'
import { ctxPracticeIdAtom, ctxIsSessionInitialized, ctxIsSidebarOpen, ctxSelectedPdoIds, ctxPdoItem, ctxIsLoggingOut, adminAtomsPracticeReset, sharedAtomsPracticeReset, sharedUiAtomsPracticeReset } from '@docpace/shared-react-atoms'

import { AdminTableBorderedRowStyle } from '@docpace/admin-react-lists/AdminList'
import { AdminProviderDepartmentOptionForm } from '../AdminProviderDepartmentOptionForm/AdminProviderDepartmentOptionForm'
import { useQuery } from '@apollo/client'
import { useManagerOverviewQuery } from '@docpace/manager-react-apollo'
import { useAdminAdminDetailQuery, useContextAdminBaseQuery } from '@docpace/admin-react-apollo'


export interface AdminLayoutProps {
    children?: any
    navChildren?: any
    isChangingRoute?: boolean
    makeNav?: () => any[]
    title?: string | (() => any)
    subtitle?: string | (() => any)
}

export const AdminLayout: FC<AdminLayoutProps> = (props) => {

    const [ practiceId, setCtxPracticeId ] = useAtom(ctxPracticeIdAtom)
    const queryPracticeId = useNextQueryParam('practiceId')
    useEffect(()=>{
        setCtxPracticeId(queryPracticeId)
    }, [ queryPracticeId ])
    
    const [ isInitialized ] = useAtom(ctxIsSessionInitialized)
    const [ selectedPdoConfig ] = useAtom(ctxPdoItem)
    const [[ configIds, ...restIds ], setSelectedPdoIds ] = useAtom(ctxSelectedPdoIds)
    const router = useRouter()
    const {
        children,
        title,
        subtitle,
        isChangingRoute,
        navChildren,
    } = props
    const isAuthPage = router?.pathname?.split('/')?.[1] === 'auth'
    const { isProd } = useAdminBase({
        skip: isAuthPage || !isInitialized,
    })
    
    const providerId = useNextQueryParam('providerId')
    const [ isSidebarOpen, setIsSidebarOpen ] = useAtom(ctxIsSidebarOpen)
    const [ isLoggingOut, setIsLoggingOut ] = useAtom(ctxIsLoggingOut)
    
    useEffect(() => {
        // note - remove in/after July 2022 after all tokens have expired
        localStorage.removeItem('access_token')
    })

    useContextAdminBaseQuery({ isAuthPage })
    const { practice, isLoading } = useAdminPracticeBase({
        skip: isAuthPage || !isInitialized,
    })
    const { adminDashboardSettings, updateAdminDashboardSettings, actor } =
        useAdminDashboardSettings({ skipQueries: !isInitialized || isAuthPage })

    const isSidebarPinned = adminDashboardSettings?.['sidebarPinned'] ?? false

    const isAnythingLoading =
        isLoading 

    const sidebarMargin = practiceId
        ? isSidebarPinned && isSidebarOpen
            ? ADMIN_SIDEBAR_DRAWER_WIDTH
            : ADMIN_SIDEBAR_DRAWER_WIDTH_CLOSED
        : 0

    const setIsSidebarPinned = async (isPinned: boolean) => {
        await updateAdminDashboardSettings('sidebarPinned', isPinned)
    }

    const handleLogout = () => {
        setIsLoggingOut(true)
        router.push(adminRoutes.Signout.href())
    }

    useAtom(adminAtomsPracticeReset)
    useAtom(sharedAtomsPracticeReset)
    useAtom(sharedUiAtomsPracticeReset)

    

    return (
        <div style={{ width: '100vw' }}>
            <Head>
                <title>DOCPACE Admin</title>
                <meta
                    name="viewport"
                    content="initial-scale=1.0, width=device-width"
                />
            </Head>

            {practiceId && (
                <ReactHoverObserver>
                    <AdminSidebar
                        practiceId={practiceId}
                        providerId={providerId}
                        isOpen={isSidebarOpen}
                        isPinned={isSidebarPinned}
                        setIsSidebarPinned={setIsSidebarPinned}
                        setIsSidebarOpen={setIsSidebarOpen}
                    />
                </ReactHoverObserver>
            )}

            <div
                style={{
                    width: `calc(100vw - ${sidebarMargin}px)`,
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: sidebarMargin,
                }}
            >
                <AdminHeader
                    subtitle={
                        typeof subtitle === 'function'
                            ? subtitle()
                            : subtitle ?? (
                                <div>
                                    {!practiceId && (
                                        <div className="cursor-pointer hover:none">
                                            Admin Dashboard
                                        </div>
                                    )}
                                    {practiceId && (
                                        <Link className="cursor-pointer hover:underline"
                                            href={
                                                practiceId
                                                    ? adminRoutes.PracticeHome.href(
                                                        { practiceId }
                                                    )
                                                    : adminRoutes.PracticeHome.href()
                                            }
                                        >
                                        {(practice?.name ?? '') +
                                            ' Admin Dashboard'}
                                        </Link>
                                    )}
                                </div>
                              )
                    }
                    title={typeof title === 'function' ? title() : title}
                    handleLogout={handleLogout}
                    isProd={isProd}
                />
                {isChangingRoute && (
                    <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
                        <span
                            className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0"
                            style={{
                                top: '50%',
                            }}
                        >
                            <i className="fas fa-circle-notch fa-spin fa-5x"></i>
                        </span>
                    </div>
                )}
                <div className={'flex flex-col scroll flex-1'}>
                    <AdminLayoutNav
                        {...props}
                        loading={isAnythingLoading}
                        children={navChildren}
                    />
                    {children}
                </div>
                <AdminTableBorderedRowStyle />
                <Dialog
                    open={!!configIds && !!selectedPdoConfig}
                    onClose={() => setSelectedPdoIds([])}
                    maxWidth='sm'
                    fullWidth
                >
                    <div className="p-2 bg-white w-full">
                        <AdminProviderDepartmentOptionForm />
                    </div>
                </Dialog>
            </div>
        </div>
    )
}

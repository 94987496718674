import { useQuery, ApolloQueryResult, QueryHookOptions } from '@apollo/client'
import { map, compact } from 'lodash'

import { AdminFavoritesQueryDocument } from '@docpace/admin-graphql/dist/urql.types'
import { AdminFavoritesQueryResultFragment, AdminFavoritesQueryParams } from '@docpace/admin-graphql'

export function useAdminFavoritesQuery(opts?: QueryHookOptions<AdminFavoritesQueryResultFragment, Partial<AdminFavoritesQueryParams>>){
    
    const queryResult = useQuery(AdminFavoritesQueryDocument, opts)
    const {
        adminFavoritePractices,
        adminFavoriteDepartments,
        adminFavoriteProviders,
        adminFavoriteAppointmentTypes,
    } = queryResult?.data ?? {}

    const favoritePracticeIds = map(
        compact(adminFavoritePractices?.nodes),
        ({ practiceId }) => practiceId
    )
    const favoriteDepartmentIds = map(
        compact(adminFavoriteDepartments?.nodes),
        ({ departmentId }) => departmentId
    )
    const favoriteProviderIds = map(
        compact(adminFavoriteProviders?.nodes),
        ({ providerId }) => providerId
    )
    const favoriteAppointmentTypeIds = map(
        compact(adminFavoriteAppointmentTypes?.nodes),
        ({ appointmentTypeId }) => appointmentTypeId
    )
    
    return {
        ...queryResult,
        favoritePracticeIds,
        favoriteDepartmentIds,
        favoriteProviderIds,
        favoriteAppointmentTypeIds,
    }
}